
import cardNormalBB1 from "../assets/card-n-bb1.png";
import cardNormalBB2 from "../assets/card-n-bb2.png";
import cardNormalBB3 from "../assets/card-n-bb3.png";
import cardNormalBB4 from "../assets/card-n-bb4.png";

export const cardNormalList = [
  {
    carId: 0,
    cardUrl: cardNormalBB1
  },
  {
    carId: 1,
    cardUrl: cardNormalBB2
  },
  {
    carId: 2,
    cardUrl: cardNormalBB3
  },
  {
    carId: 3,
    cardUrl: cardNormalBB4
  },
]